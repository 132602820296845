import { create } from "./create"

export const useAppStore = create<{
  bottomNavHeight: number
  setBottomNavHeight: (height: number) => void

  exitOnLogout: boolean
  setExitOnLogout: (exitOnLogout: boolean) => void

  datePickerDate: Date
  setDatePickerDate: (date: Date) => void

  authenticationStartTime: Date | null
  setAuthenticationStartTime: (time: Date | null) => void
}>((set) => {
  return {
    bottomNavHeight: 0,
    setBottomNavHeight: (height: number) =>
      set({
        bottomNavHeight: height,
      }),

    exitOnLogout: false,
    setExitOnLogout: (exitOnLogout: boolean) => {
      set({
        exitOnLogout,
      })
    },

    datePickerDate: new Date(),
    setDatePickerDate: (date: Date) => {
      set({
        datePickerDate: date,
      })
    },

    authenticationStartTime: null,
    setAuthenticationStartTime: (time: Date | null) => {
      set({
        authenticationStartTime: time,
      })
    },
  }
})
