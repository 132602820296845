import { createContext } from "react"
import { User } from "@auth0/auth0-react"

type IAuthContext = {
  readonly isLoading: boolean
  readonly isAuthenticated: boolean
  readonly error: Error | null
  readonly user: User | null
  readonly getAccessToken: () => Promise<string | undefined>
  readonly login: () => Promise<void>
  readonly signup: () => Promise<void>
  readonly logout: () => Promise<void>
}

const defaultAuthContext = {
  isLoading: false,
  isAuthenticated: false,
  error: null,
  user: null,
  getAccessToken: () => {
    throw new Error("Not implemented")
  },
  login: () => {
    throw new Error("Not implemented")
  },
  signup: () => {
    throw new Error("Not implemented")
  },
  logout: () => {
    throw new Error("Not implemented")
  },
} satisfies IAuthContext

export const AuthContext = createContext<IAuthContext>(defaultAuthContext)
