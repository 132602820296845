import { useAuth, useHttpClient } from "@my/api"
import { writeToDebug } from "@my/config/src/debug"
import { Button } from "@my/ui"
import { useNetInfoInstance } from "@react-native-community/netinfo"
import { HealthyLogo } from "app/assets/images/HealthyLogo"
import { useFeatureFlagClient } from "app/feature-flags"
import { LegalLinks } from "app/features/settings/LegalLinks"
import { useAppStore } from "app/global-state/appStore"
import { useUserStore } from "app/global-state/userState"
import { LogoutType, useLogout } from "app/hooks/useLogout"
import { useStoredBoolean } from "app/storage"
import { mixpanel, setMonitoringUser, trackButtonClicked } from "app/telemetry"
import { formatUtmForMixpanel } from "app/utm/formatUtmForMixpanel"
import { useUtm } from "app/utm/useUtm"
import { hideAsync as hideSplashScreenAsync } from "expo-splash-screen"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  SizableText,
  Spinner,
  Text,
  View,
  YStack,
  isWeb,
  useMedia,
  useWindowDimensions,
} from "tamagui"
import { getStartedShownKey, usePostUser } from "../usePostUser"

export function LoginFlow({ children }: { children: React.ReactNode }) {
  const ffClient = useFeatureFlagClient()
  const { handleLogout } = useLogout()
  const { isLoading, isAuthenticated } = useAuth()
  const { user } = useUserStore()
  const {
    netInfo: { isInternetReachable },
  } = useNetInfoInstance()
  const [waitlist, setWaitlist] = useState(false)
  const [isGeoblocked, setIsGeoblocked] = useState(false)
  const [showLogoutButton, setShowLogoutButton] = useState(false)
  const [getStartedShown, setGetStartedShown] = useStoredBoolean(getStartedShownKey, isWeb)

  const wasGetStartedShown = useMemo(() => {
    // Let's not incorrectly return false if we're still loading this from storage. That would trigger
    // a handleLogout() call.
    if (getStartedShown === undefined) {
      return undefined
    }

    // On web, we never showed the get started screen
    return getStartedShown || isWeb
  }, [getStartedShown, isWeb])

  // This will create/fetch the user from the backend after they have authenticated.
  usePostUser({ setWaitlist, setIsGeoblocked })

  /*
   * On iOS the authentication is stored in the keychain which means if a user uninstalls/reinstalls they could
   * open the app and still be logged in. This catches that security risk and logs the user out.
   */
  useEffect(() => {
    if (wasGetStartedShown === false && isAuthenticated) {
      console.warn("Logging out user due to existing auth on new app install")
      // TODO: Reenable this once we allow existing users to have the new key added to their
      // local storage. This prevents all exisiting users from being logged out.
      // handleLogout(LogoutType.Reinstall)
    }
  }, [wasGetStartedShown, isAuthenticated])

  // Necessary for above bug
  useEffect(() => {
    /*
     * For already logged in users, they will not see the GetStarted screen which means we need to
     * explicitly set this to avoid an eventual handleLogout() call we'll add in the future to handle
     * ios storing credentials in the keychain.
     */
    if (wasGetStartedShown === false && !isLoading && isAuthenticated && user) {
      setGetStartedShown(true)
    }
  }, [wasGetStartedShown, isLoading, isAuthenticated, user])

  useEffect(() => {
    if (isLoading || !user) {
      const timer = setTimeout(() => {
        if (isLoading || !user) {
          setShowLogoutButton(true)
        }
      }, 5000)

      return () => clearTimeout(timer)
    } else {
      setShowLogoutButton(false)
    }
  }, [isLoading, user])

  useEffect(() => {
    if (user) {
      writeToDebug(`Identifying user in mixpanel. user(${user.auth0Id})`)
      setMonitoringUser(user)
      ffClient.identify(user.email)
    }
  }, [user, ffClient])

  if (isInternetReachable !== false) {
    if (!isLoading && isAuthenticated && user) {
      return <>{children}</>
    } else if (!isLoading && waitlist) {
      return <WaitlistScreen setWaitlist={setWaitlist} />
    } else if (!isLoading && isGeoblocked) {
      return <LandingScreen isGeoblocked />
    } else if (!isLoading && !isAuthenticated) {
      return <GetStartedScreen setGetStartedShown={setGetStartedShown} />
    }
  }

  return (
    <LandingScreen isLoading>
      {showLogoutButton && (
        <Button variant="primary" onPress={() => handleLogout(LogoutType.AuthIssue)}>
          Back to Login
        </Button>
      )}
    </LandingScreen>
  )
}

function GetStartedScreen({
  setGetStartedShown,
}: {
  setGetStartedShown: (value: boolean) => void
}) {
  return (
    <LandingScreen>
      <GetStarted setGetStartedShown={setGetStartedShown} />
    </LandingScreen>
  )
}

function WaitlistScreen({ setWaitlist }: { setWaitlist: (waitlist: boolean) => void }) {
  const httpClient = useHttpClient()
  const [hasJoinedWaitlist, setHasJoinedWaitlist] = useState<boolean>(false)
  const { logout } = useAuth()
  const { sm } = useMedia()

  const handleJoinWaitlist = useCallback(async () => {
    if (!hasJoinedWaitlist) {
      const response = await httpClient.post(`/private/system/request-access`)
      if (response) {
        setHasJoinedWaitlist(true)
      }
    }
  }, [hasJoinedWaitlist, httpClient])

  return (
    <LandingScreen>
      {hasJoinedWaitlist ?
        <Text textAlign="center">
          Thank you for joining the waitlist.{"\n"}
          We’ll send you an email when your invite is ready.
        </Text>
      : <Text textAlign="center" width={sm ? "85%" : "100%"}>
          Healthy is currently available by invite only.{"\n"}
          If you would like to join the waitlist, please sign up below.
        </Text>
      }
      {!hasJoinedWaitlist && (
        <Button variant="primary" width={300} marginTop="$base" onPress={handleJoinWaitlist}>
          Join Waitlist
        </Button>
      )}
      <Button
        unstyled
        width={300}
        color="$textAction"
        marginTop="$base"
        onPress={() => {
          setWaitlist(false)
          logout()
        }}
      >
        Start Over
      </Button>
    </LandingScreen>
  )
}

function LandingScreen({
  children,
  isLoading = false,
  isGeoblocked = false,
}: {
  children?: React.ReactNode
  isLoading?: boolean
  isGeoblocked?: boolean
}) {
  const { gtLg, xxs } = useMedia()
  const { height } = useWindowDimensions()
  const {
    netInfo: { isInternetReachable },
  } = useNetInfoInstance()
  const isOnline = isInternetReachable !== false

  useEffect(() => {
    if (!isLoading) {
      hideSplashScreenAsync()
    }
  }, [isLoading])

  return (
    <View flex={1} height={height} $gtSm={{ alignItems: "center" }} width="100%">
      <YStack
        flex={1}
        $gtSm={{
          width: 600,
        }}
        alignItems="center"
        justifyContent="center"
        padding="$base"
      >
        <View flex={1} />
        <View height={88}>
          <HealthyLogo width={300} height={88} />
        </View>
        {isOnline && !isGeoblocked && (
          <Text
            color="$text"
            paddingTop="$lg"
            fontSize={
              gtLg ? 22
              : xxs ?
                16
              : 16
            }
            lineHeight={
              gtLg ? 26
              : xxs ?
                17
              : 19
            }
          >
            Log, track, and learn one conversation at a time.
          </Text>
        )}
        {isOnline && isGeoblocked && (
          <Text color="$text" paddingTop="$lg">
            Thank you for your interest in Healthy. Unfortunately, Healthy is only available in the
            United States.
          </Text>
        )}
        <View flex={1} />
        {children}
        <View flex={1} />
        {isLoading ?
          <>
            <Spinner size="large" color="$backgroundInfo" />
            <View height="$1" />
          </>
        : null}
        {isOnline ?
          <LegalLinks />
        : <>
            <SizableText color="$text" fontSize="$4" textAlign="center">
              You are offline.
            </SizableText>
            <View height="$0.75" />
            <SizableText color="$text" fontSize="$3" textAlign="center">
              Please check your connection.
            </SizableText>
          </>
        }
      </YStack>
    </View>
  )
}

function GetStarted({ setGetStartedShown }: { setGetStartedShown: (value: boolean) => void }) {
  const { login, signup } = useAuth()
  const { utmParameters, isLoading: isUtmLoading } = useUtm()
  const { setAuthenticationStartTime } = useAppStore()

  useEffect(() => {
    setGetStartedShown(true)
  }, [])

  mixpanel.trackOnMount(
    { page_name: "LoginSelection", ...formatUtmForMixpanel(utmParameters) },
    isUtmLoading,
  )

  return (
    <>
      <Button
        variant="primary"
        width={300}
        onPress={() => {
          setAuthenticationStartTime(new Date())
          signup()
          trackButtonClicked("SignUp", "LoginSelection", { ...formatUtmForMixpanel(utmParameters) })
        }}
        data-testid="login-flow-get-started"
      >
        Get Started
      </Button>
      <View height="$1" />
      <Button
        variant="secondary"
        width={300}
        onPress={() => {
          setAuthenticationStartTime(new Date())
          login()
          trackButtonClicked("LogIn", "LoginSelection", { ...formatUtmForMixpanel(utmParameters) })
        }}
        data-testid="login-flow-login"
      >
        Log In
      </Button>
    </>
  )
}
