import { User } from "@my/api"
import { create, registerStoreResetFn } from "./create"
import { useStorage } from "../storage"
import { useEffect, useMemo } from "react"

type UserStore = {
  user: User | null
  setUser: (user: User | null) => void
  hasHitUsersEndpoint: boolean
  setHasHitUsersEndpoint: (hasHit: boolean) => void
  tosAccepted: boolean
  setTosAccepted: (accepted: boolean) => void
}

export function useUserStore(): UserStore {
  const wrappedStore = _useUserStore()
  const storage = useStorage()

  useEffect(() => {
    if (hasSetFromStorage) {
      return
    }

    const user = storage.getString("user")
    wrappedStore.setUser(user ? JSON.parse(user) : null)
    hasSetFromStorage = true

    registerStoreResetFn(() => {
      storage.delete("user")
    })
  }, [])

  return useMemo(
    () => ({
      ...wrappedStore,
      setUser: (user: User | null) => {
        wrappedStore.setUser(user)

        if (user) {
          storage.set("user", JSON.stringify(user))
        } else {
          storage.delete("user")
        }
      },
    }),
    [wrappedStore, storage],
  )
}

let hasSetFromStorage = false

const _useUserStore = create<UserStore>((set) => ({
  user: null,
  setUser: (user: User | null) => set({ user: user ? userWithInitials(user) : null }),
  hasHitUsersEndpoint: false,
  setHasHitUsersEndpoint: (hasHit: boolean) => set({ hasHitUsersEndpoint: hasHit }),
  tosAccepted: false,
  setTosAccepted: (accepted: boolean) => set({ tosAccepted: accepted }),
}))

function userWithInitials(user: User): User {
  return {
    ...user,
    initials: `${user?.givenName?.[0] ?? ""}${user?.familyName?.[0] ?? ""}`,
  }
}
