import { useAuth } from "@my/api"
import { useMemo } from "react"
import { resetAllStores } from "../global-state"
import { mixpanel } from "../telemetry/mixpanel"
import { useQueryClient } from "@tanstack/react-query"

export enum LogoutType {
  User = "User",
  EnvSwitch = "Environment Switch",
  AuthIssue = "Auth Issue",
  Reinstall = "Reinstall",
  BackendError = "Backend Error",
}

export const useLogout = () => {
  const { logout } = useAuth()
  const queryClient = useQueryClient()

  return useMemo(
    () => ({
      handleLogout: async (type: LogoutType) => {
        // Finish a logout before clearing out zustand stores
        await logout()
        // Reset all global state (Zustand stores)
        resetAllStores()
        // Clear the query client caches
        queryClient.clear()

        mixpanel.track("Logout", { type })
      },
    }),
    [logout],
  )
}
