import { useHttpClient, UserUtmParameters } from "@my/api"
import { Button, CheckboxWithLabel, ShadowlessDialogContent } from "@my/ui"
import { useUserStore } from "app/global-state/userState"
import { mixpanel, trackButtonClicked } from "app/telemetry"
import { useUtm } from "app/utm/useUtm"
import { useCallback, useEffect, useState, memo, useMemo } from "react"
import { Dialog, H2, Text, YStack, styled, useMedia } from "tamagui"
import { formatUtmForMixpanel } from "app/utm/formatUtmForMixpanel"
import { Platform, Linking, Dimensions } from "react-native"
import { LEGAL_URLS } from "@my/api/src/constants"
import { useStoredBoolean } from "app/storage/useStorage"

/**
 * Represents the Terms of Service data structure.
 * This interface defines the shape of the Terms of Service object
 * returned from the API and used throughout the Terms and Conditions flow.
 */
interface TermsOfService {
  /** Unique identifier for the Terms of Service version */
  id: string
  /** Version number/string of the Terms of Service document */
  version: string
}

/**
 * Props interface for the TermsDialog component.
 * Defines the required properties for rendering and managing the Terms and Conditions dialog.
 */
interface TermsDialogProps {
  /** Whether the user has already consented to the terms */
  consented: boolean
  /** The current Terms of Service data */
  termsOfService: TermsOfService
  /** Whether the user has accepted the Terms of Service */
  acceptTos: boolean
  /** Whether the user has accepted the Privacy Policy */
  acceptPrivacyPolicies: boolean
  /** Callback to update Terms of Service acceptance state */
  setAcceptTos: (value: boolean) => void
  /** Callback to update Privacy Policy acceptance state */
  setAcceptPrivacyPolicies: (value: boolean) => void
  /** Callback to handle user consent submission */
  handleConsent: () => Promise<void>
  /**
   * Callback to handle external link presses
   * @param url - The URL path to append to the base URL
   * @param trackingName - Name for analytics tracking
   * @param screenName - Optional screen name for analytics context
   */
  handleLinkPress: (url: string, trackingName: string, screenName?: string) => void
}

const StyledH2 = styled(H2, {
  color: "$text",
  fontWeight: "700",
  variants: {
    small: {
      true: {
        fontSize: "$8",
      },
      false: {
        fontSize: "$9",
      },
    },
  } as const,
})
const StyledText = styled(Text, {
  color: "$text",
  fontWeight: "400",
  variants: {
    small: {
      true: {
        fontSize: "$3",
      },
      false: {
        fontSize: "$4",
      },
    },
  } as const,
})

/**
 * Dialog component that displays Terms and Conditions and Privacy Policy acceptance UI
 * Handles user consent flows for both web and mobile platforms
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.consented - Whether user has already consented to terms
 * @param {TermsOfService} props.termsOfService - Current Terms of Service data
 * @param {boolean} props.acceptTos - Whether Terms of Service are accepted
 * @param {boolean} props.acceptPrivacyPolicies - Whether Privacy Policy is accepted
 * @param {(value: boolean) => void} props.setAcceptTos - Updates Terms acceptance state
 * @param {(value: boolean) => void} props.setAcceptPrivacyPolicies - Updates Privacy Policy acceptance state
 * @param {() => Promise<void>} props.handleConsent - Handles submission of user consent
 * @param {(url: string, trackingName: string, screenName?: string) => void} props.handleLinkPress - Handles external link presses
 * @returns {JSX.Element} Terms and conditions dialog
 */
const TermsDialog = memo(
  ({
    consented,
    termsOfService,
    acceptTos,
    acceptPrivacyPolicies,
    setAcceptTos,
    setAcceptPrivacyPolicies,
    handleConsent,
    handleLinkPress,
  }: TermsDialogProps) => {
    const { xs } = useMedia()
    const [hasAcceptedTerms] = useStoredBoolean("hasAcceptedTerms")

    return (
      <Dialog open={!consented} modal onOpenChange={() => {}}>
        <Dialog.Portal>
          <Dialog.Overlay key="overlay" backgroundColor="rgba(0, 0, 0, 0.5)" />
          <ShadowlessDialogContent
            key="content"
            maxWidth={Math.min(Dimensions.get("window").width * 0.8, 500)}
            paddingTop="$5"
            paddingLeft="$3.5"
            paddingRight="$3.5"
            paddingBottom="$3.5"
            borderRadius="$6"
            backgroundColor="$background"
          >
            <YStack gap="$4" testID="terms-and-conditions-container">
              <Dialog.Title>
                <StyledH2 small={xs} testID="terms-and-conditions-header">
                  Before you get started
                </StyledH2>
              </Dialog.Title>

              <YStack gap="$3">
                <StyledText small={xs}>
                  This AI-powered app is designed to offer educational health information, it is not
                  intended to provide medical advice. Conversations are AI-generated, not provided
                  by a human. Always consult a healthcare provider with any health-related
                  questions.
                </StyledText>

                <StyledText small={xs}>
                  In a medical emergency, call 911 or seek immediate care. Do not delay treatment
                  based on app advice.
                </StyledText>
              </YStack>

              <CheckboxWithLabel
                textId="acceptAll"
                checked={acceptTos && acceptPrivacyPolicies}
                testID="terms-and-conditions-checkbox"
                onCheckedChange={(checked) => {
                  setAcceptTos(checked as boolean)
                  setAcceptPrivacyPolicies(checked as boolean)
                }}
                label={
                  <StyledText flex={1} flexWrap="wrap" color="$text" small={xs}>
                    I have read and accept the{" "}
                    <StyledText
                      color="$textLink"
                      onPress={() => handleLinkPress(LEGAL_URLS.TERMS_OF_USE, "TermsOfUse")}
                      small={xs}
                    >
                      Terms of Service
                    </StyledText>
                    ,{" "}
                    <StyledText
                      color="$textLink"
                      onPress={() => handleLinkPress(LEGAL_URLS.PRIVACY_POLICY, "PrivacyPolicy")}
                      small={xs}
                    >
                      Privacy Policy
                    </StyledText>
                    , and{" "}
                    <StyledText
                      color="$textLink"
                      onPress={() => handleLinkPress(LEGAL_URLS.MHMD_ACT, "MHMDActHealthPrivacy")}
                      small={xs}
                    >
                      Consumer Health Data Privacy Policy
                    </StyledText>
                    .
                  </StyledText>
                }
              />

              <Button
                variant="primary"
                borderRadius="$4"
                disabled={!acceptTos || !acceptPrivacyPolicies}
                testID="terms-and-conditions-get-started-button"
                onPress={() => {
                  handleConsent()
                  trackButtonClicked("Accept", "TermsAndConditions", {
                    tos_version: termsOfService?.version,
                    first_time_user: !hasAcceptedTerms,
                  })
                }}
              >
                Get Started
              </Button>
            </YStack>
          </ShadowlessDialogContent>
        </Dialog.Portal>
      </Dialog>
    )
  },
)

/**
 * Props interface for the TermsAndConditions component.
 * Defines the required properties for managing user consent to terms and conditions.
 */
interface TermsAndConditionsProps {
  /** Whether the user has already consented to the terms */
  consented: boolean
  /** Callback to update the user's consent state */
  setConsented: (value: boolean) => void
  /** Child components to render after consent is given */
  children: React.ReactNode
}

/**
 * Component that handles the Terms and Conditions acceptance flow.
 * Displays a dialog with Terms of Service and Privacy Policy checkboxes that users must accept
 * before proceeding. Handles fetching the latest terms version and submitting user consent.
 *
 * @component
 * @param {TermsAndConditionsProps} props - Component props
 * @param {boolean} props.consented - Whether the user has already consented to the terms
 * @param {(value: boolean) => void} props.setConsented - Callback to update the user's consent state
 * @param {React.ReactNode} props.children - Child components to render after consent is given
 * @returns {JSX.Element} Terms and conditions dialog or children if already consented
 */
export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  consented,
  setConsented,
  children,
}) => {
  const { user } = useUserStore()
  const [loading, setLoading] = useState(true)
  const [termsOfService, setTermsOfService] = useState<TermsOfService>()
  const [acceptTos, setAcceptTos] = useState(false)
  const [acceptPrivacyPolicies, setAcceptPrivacyPolicies] = useState(false)
  const httpClient = useHttpClient()
  const { utmParameters, isLoading: isUtmLoading } = useUtm()
  const [hasAcceptedTerms] = useStoredBoolean("hasAcceptedTerms", false)

  const shouldTrackMount = useMemo(() => {
    // Make sure hasAcceptedTerms is loaded from storage and utmParameters are finished loading
    return hasAcceptedTerms !== undefined && !isUtmLoading
  }, [hasAcceptedTerms, isUtmLoading])

  const fetchTerms = useCallback(async () => {
    const tos = await httpClient.get<{ terms: TermsOfService }>("/terms-of-service")
    if (tos) {
      setLoading(false)
      setTermsOfService(tos?.terms)
    }
  }, [httpClient])

  // Fetch the TOS consented status when the logged-in user's Id changes.
  useEffect(() => {
    if (user?.id) {
      fetchTerms()
    }
  }, [user?.id, fetchTerms])

  const handleConsent = useCallback(async () => {
    const updateConsent = await httpClient.post(`/terms-of-service/${termsOfService!.id}/consent`)
    if (updateConsent) {
      setConsented(true)
      mixpanel.setProfile({
        registered_user: true,
        platform: Platform.OS,
      })
    }
  }, [httpClient, setConsented, termsOfService])

  const handleLinkPress = useCallback(
    (url: string, trackingName: string, screenName: string = "TermsAndConditions") => {
      trackButtonClicked(trackingName, screenName)
      Linking.openURL(url)
    },
    [],
  )

  return (
    <>
      {shouldTrackMount && (
        <TermsAndConditionsTracker
          utmParameters={utmParameters}
          hasAcceptedTerms={!!hasAcceptedTerms}
        />
      )}
      {children}
      {!loading && (
        <TermsDialog
          consented={consented}
          termsOfService={termsOfService!}
          acceptTos={acceptTos}
          acceptPrivacyPolicies={acceptPrivacyPolicies}
          setAcceptTos={setAcceptTos}
          setAcceptPrivacyPolicies={setAcceptPrivacyPolicies}
          handleConsent={handleConsent}
          handleLinkPress={handleLinkPress}
        />
      )}
    </>
  )
}

export const TermsAndConditionsTracker = memo(
  ({
    utmParameters,
    hasAcceptedTerms,
  }: {
    utmParameters: UserUtmParameters | undefined
    hasAcceptedTerms: boolean
  }) => {
    // This can not be in a useEffect as it internally is in a useFocusEffect() already
    mixpanel.trackOnMount(
      {
        page_name: "TermsAndConditions",
        ...formatUtmForMixpanel(utmParameters),
        first_time_user: !hasAcceptedTerms,
      },
      // We won't render this component until loading is complete so we can safely pass false here
      false,
    )

    return null
  },
)
