import { isJest } from "@my/config/src/constants"
import { Environment } from "@my/config/src/environment"
import { Mixpanel, MixpanelEvent, MixpanelProperties } from "app/types/mixpanel"
import { Config, default as sdkMixpanel } from "mixpanel-browser"
import { useEffect, useRef } from "react"

const ENABLED = !isJest() && Environment.MIXPANEL_TOKEN !== "disabled"
const mp = ENABLED ? sdkMixpanel : undefined
console.debug("Mixpanel enabled", ENABLED)

export const mixpanel: Mixpanel = {
  init: () => {
    const config: Partial<Config> = {
      ignore_dnt: true,
      ip: false,
    }
    mp?.init(Environment.MIXPANEL_TOKEN, config)
  },
  identify: (authId: string): Promise<void> | undefined => {
    return mp?.identify(authId)
  },
  setProfile: (properties) => {
    mp?.people.set(properties)
  },
  setSuperProperties: (properties) => {
    mp?.register(properties)
  },
  clearMixpanelData: () => {
    mp?.reset()
  },
  track: (eventName: MixpanelEvent, properties: MixpanelProperties) => {
    mp?.track(eventName, properties)
  },
  trackOnMount: (properties: MixpanelProperties, isLoading = false) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const hasTracked = useRef(false)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (hasTracked.current) return

      if (!isLoading) {
        mp?.track("Page Viewed", properties)
        hasTracked.current = true
      }
    }, [isLoading, properties])
  },
}
