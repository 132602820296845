import { library } from "@fortawesome/fontawesome-svg-core"

import {
  faAddressCard as farAddressCard,
  faArrowLeft as farArrowLeft,
  faBars as farBars,
  faBedFront as farBedFront,
  faCalendar as farCalendar,
  faCaretLeft as farCaretLeft,
  faCaretRight as farCaretRight,
  faCheck as farCheck,
  faChevronDown as farChevronDown,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronUp as farChevronUp,
  faCircleArrowUp as farCircleArrowUp,
  faCircleCheck as farCircleCheck,
  faCircleExclamation as farCircleExclamation,
  faCircleInfo as farCircleInfo,
  faCircleQuestion as farCircleQuestion,
  faCircleX as farCircleX,
  faCirclePlus as farCirclePlus,
  faClipboardMedical as farClipboardMedical,
  faClock as farClock,
  faCog as farCog,
  faCommentCheck as farCommentCheck,
  faCommentDots as farCommentDots,
  faCommentLines as farCommentLines,
  faCompass as farCompass,
  faCopy as farCopy,
  faEclipse as farEclipse,
  faEdit as farEdit,
  faEllipsisVertical as farEllipsisVertical,
  faEnvelope as farEnvelope,
  faFaceAngry as farFaceAngry,
  faFaceAwesome as farFaceAwesome,
  faFaceSmile as farFaceSmile,
  faFaceVomit as farFaceVomit,
  faFilePrescription as farFilePrescription,
  faGauge as farGauge,
  faHeart as farHeart,
  faHouse as farHouse,
  faImageLandscape as farImageLandscape,
  faMessageCheck as farMessageCheck,
  faMessageMedical as farMessageMedical,
  faMessages as farMessages,
  faPalette as farPalette,
  faPlateUtensils as farPlateUtensils,
  faPlus as farPlus,
  faServer as farServer,
  faSidebar as farSidebar,
  faSidebarFlip as farSidebarFlip,
  faSliders as farSliders,
  faSparkles as farSparkles,
  faStethoscope as farStethoscope,
  faThumbsDown as farThumbsDown,
  faThumbsUp as farThumbsUp,
  faUser as farUser,
  faUserCircle as farUserCircle,
  faVial as farVial,
  faWeight as farWeight,
  faX as farX,
  faRollerCoaster as farRollerCoaster,
  faTrashCan as farTrashCan,
} from "@fortawesome/pro-regular-svg-icons"

import {
  faAddressCard as fasAddressCard,
  faArrowLeft as fasArrowLeft,
  faBars as fasBars,
  faBedFront as fasBedFront,
  faCalendar as fasCalendar,
  faCaretLeft as fasCaretLeft,
  faCaretRight as fasCaretRight,
  faCheck as fasCheck,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faCircleArrowUp as fasCircleArrowUp,
  faCircleCheck as fasCircleCheck,
  faCircleExclamation as fasCircleExclamation,
  faCircleInfo as fasCircleInfo,
  faCircleQuestion as fasCircleQuestion,
  faCircleX as fasCircleX,
  faCirclePlus as fasCirclePlus,
  faClipboardMedical as fasClipboardMedical,
  faClock as fasClock,
  faCog as fasCog,
  faCommentCheck as fasCommentCheck,
  faCommentDots as fasCommentDots,
  faCommentLines as fasCommentLines,
  faCompass as fasCompass,
  faCopy as fasCopy,
  faEclipse as fasEclipse,
  faEdit as fasEdit,
  faEllipsisVertical as fasEllipsisVertical,
  faEnvelope as fasEnvelope,
  faFaceAngry as fasFaceAngry,
  faFaceAwesome as fasFaceAwesome,
  faFaceSmile as fasFaceSmile,
  faFaceVomit as fasFaceVomit,
  faFilePrescription as fasFilePrescription,
  faGauge as fasGauge,
  faHeart as fasHeart,
  faHouse as fasHouse,
  faImageLandscape as fasImageLandscape,
  faMessageCheck as fasMessageCheck,
  faMessageMedical as fasMessageMedical,
  faMessages as fasMessages,
  faPalette as fasPalette,
  faPlateUtensils as fasPlateUtensils,
  faPlus as fasPlus,
  faServer as fasServer,
  faSidebar as fasSidebar,
  faSidebarFlip as fasSidebarFlip,
  faSliders as fasSliders,
  faSparkles as fasSparkles,
  faStethoscope as fasStethoscope,
  faThumbsDown as fasThumbsDown,
  faThumbsUp as fasThumbsUp,
  faUser as fasUser,
  faUserCircle as fasUserCircle,
  faVial as fasVial,
  faWeight as fasWeight,
  faX as fasX,
  faRollerCoaster as fasRollerCoaster,
  faTrashCan as fasTrashCan,
} from "@fortawesome/pro-solid-svg-icons"

export function registerIcons() {
  library.add(
    farAddressCard,
    fasAddressCard,
    farArrowLeft,
    fasArrowLeft,
    farBars,
    fasBars,
    farBedFront,
    fasBedFront,
    farCalendar,
    fasCalendar,
    farCheck,
    fasCheck,
    farChevronDown,
    fasChevronDown,
    farChevronLeft,
    fasChevronLeft,
    farChevronRight,
    fasChevronRight,
    farChevronUp,
    fasChevronUp,
    farCircleArrowUp,
    fasCircleArrowUp,
    farCircleCheck,
    fasCircleCheck,
    farCircleExclamation,
    fasCircleExclamation,
    farCircleInfo,
    fasCircleInfo,
    farCircleQuestion,
    fasCircleQuestion,
    farCircleX,
    fasCircleX,
    farCirclePlus,
    fasCirclePlus,
    farClipboardMedical,
    fasClipboardMedical,
    farClock,
    fasClock,
    farCog,
    fasCog,
    farCommentCheck,
    fasCommentCheck,
    farCommentDots,
    fasCommentDots,
    farCommentLines,
    fasCommentLines,
    farCompass,
    fasCompass,
    farCopy,
    fasCopy,
    farEclipse,
    fasEclipse,
    farEdit,
    fasEdit,
    farEllipsisVertical,
    fasEllipsisVertical,
    farEnvelope,
    fasEnvelope,
    farFaceAngry,
    fasFaceAngry,
    farFaceAwesome,
    fasFaceAwesome,
    farFaceSmile,
    fasFaceSmile,
    farFaceVomit,
    fasFaceVomit,
    farFilePrescription,
    fasFilePrescription,
    farGauge,
    fasGauge,
    farHeart,
    fasHeart,
    farHouse,
    fasHouse,
    farImageLandscape,
    fasImageLandscape,
    farMessages,
    fasMessages,
    fasMessageCheck,
    farMessageCheck,
    farMessageMedical,
    fasMessageMedical,
    farPalette,
    fasPalette,
    farPlateUtensils,
    fasPlateUtensils,
    farServer,
    fasServer,
    farSidebar,
    fasSidebar,
    farSidebarFlip,
    fasSidebarFlip,
    farSparkles,
    fasSparkles,
    farStethoscope,
    fasStethoscope,
    farThumbsDown,
    fasThumbsDown,
    farThumbsUp,
    fasThumbsUp,
    farUser,
    fasUser,
    farUserCircle,
    fasUserCircle,
    farVial,
    fasVial,
    farWeight,
    fasWeight,
    farX,
    fasX,
    farArrowLeft,
    fasArrowLeft,
    farSliders,
    fasSliders,
    farPlus,
    fasPlus,
    farCaretLeft,
    fasCaretLeft,
    farCaretRight,
    fasCaretRight,
    farRollerCoaster,
    fasRollerCoaster,
    farTrashCan,
    fasTrashCan,
  )
}
