import { TermsAndConditions } from "./TermsAndConditions"
import { LoginFlow } from "./LoginFlow"
import { isWeb } from "tamagui"
import { useAuth } from "@my/api"
import { TimezoneListener } from "./TimezoneListener"
import LoggedOut from "./LoggedOut"
import { useTermsOfService } from "app/hooks/useTermsOfService"
import { useHandlePendingModuleSubscription } from "app/hooks/useHandlePendingModuleSubscription"

export const StartupFlow = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuth()
  const { tosAccepted, setTosAccepted, showTos } = useTermsOfService()

  useHandlePendingModuleSubscription()

  /*
   * The !isLoading check prevents logged out from showing on browser refresh but also ends up keeping logout page
   * from showing while a logout is in progress since isLoading will be true. User just sees LoginFlow which is fine.
   */
  if (isWeb && !isLoading && !isAuthenticated) {
    return <LoggedOut>{children}</LoggedOut>
  }

  return (
    <LoginFlow>
      {showTos ?
        <TermsAndConditions consented={tosAccepted} setConsented={setTosAccepted}>
          <TimezoneListener>{children}</TimezoneListener>
        </TermsAndConditions>
      : <TimezoneListener>{children}</TimezoneListener>}
    </LoginFlow>
  )
}
