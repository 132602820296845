import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useUserStore } from "app/global-state/userState"
import { useAuth, useHttpClient } from "@my/api"
import { writeToDebug } from "@my/config/src/debug"
import { useStoredBoolean } from "../storage/useStorage"

/**
 * Represents the state of a Terms of Service consent fetch operation
 * @type {Object | null} - Null when no fetch is in progress
 */
type FetchState = {
  /**
   * Promise resolving to the user's consent status
   */
  promise: Promise<boolean | null>
  /**
   * ID of the user making the request, undefined if not authenticated
   */
  userId: string | undefined
} | null

/**
 * Hook to manage Terms of Service state and logic
 * @returns Object containing TOS state and visibility
 */
export function useTermsOfService() {
  const { tosAccepted, setTosAccepted } = useUserStore()
  const [hasAcceptedTerms, setHasAcceptedTerms] = useStoredBoolean("hasAcceptedTerms", false)
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth()
  const httpClient = useHttpClient()
  // Short-circut to avoid fetching multiple times concurrently
  const fetchStateRef = useRef<FetchState>(null)
  // Ref to track the user ID of the already fetched TOS consent
  const fetchedTosConsentRef = useRef<string | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const { user } = useUserStore()

  // consented endpoint requires a user
  const authenticated = useMemo(
    () => !isAuthLoading && isAuthenticated && user,
    [isAuthenticated, isAuthLoading, user],
  )

  useEffect(() => {
    if (tosAccepted && hasAcceptedTerms === false) {
      // This is only used for analytics to determine if this is a first time user
      setHasAcceptedTerms(true)
    }
  }, [tosAccepted, hasAcceptedTerms])

  const fetchTerms = useCallback(async () => {
    const promise = httpClient.get<boolean>("/terms-of-service/consented")
    // Store both the promise and userId
    fetchStateRef.current = {
      promise,
      userId: user?.id,
    }

    try {
      const userConsent = await promise

      if (userConsent) {
        writeToDebug("User has consented to TOS")
        setTosAccepted(true)
      } else {
        writeToDebug("User has not consented to TOS")
      }
    } finally {
      // Set the fetched TOS consent ref to the current user ID
      fetchedTosConsentRef.current = user?.id
      // Reset the fetch state ref to allow for another fetch
      fetchStateRef.current = null
      setLoading(false)
    }
  }, [httpClient, user?.id, setTosAccepted])

  useEffect(() => {
    if (!authenticated) {
      return
    }

    if (!user?.id) {
      return
    }

    // If we have already fetched for this user once during this app session, skip.
    const currentFetchedTosConsent = fetchedTosConsentRef.current
    if (currentFetchedTosConsent && currentFetchedTosConsent === user?.id) {
      return
    }

    // If we're in the process of fetching for this user, skip
    const currentFetchState = fetchStateRef.current
    if (currentFetchState && currentFetchState.userId === user?.id) {
      return
    }

    // Otherwise, fetch the TOS consent
    fetchTerms()
  }, [user?.id, isAuthenticated, fetchTerms])

  const showTos = useMemo(() => {
    return isAuthenticated && !loading && tosAccepted === false
  }, [isAuthenticated, tosAccepted, loading])

  return useMemo(
    () => ({
      tosAccepted,
      setTosAccepted,
      showTos,
    }),
    [tosAccepted, setTosAccepted, showTos],
  )
}
