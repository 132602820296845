import { useEffect } from "react"

export type EventName = string

export interface EventSubscription {
  remove: () => void
}
class EventSubscriptionImpl implements EventSubscription {
  eventBus: EventBusImpl
  event: EventName
  key: number

  constructor(eventBus: EventBusImpl, event: EventName, key: number) {
    this.eventBus = eventBus
    this.event = event
    this.key = key
  }

  remove() {
    this.eventBus.removeListener(this)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Listeners = Map<number, (context: any) => void | Promise<void>>

class EventBusImpl {
  private readonly listeners = new Map<EventName, Listeners>()
  private key = 1

  addListener<T>(
    event: EventName,
    listener: (context: T) => void | Promise<void>,
  ): EventSubscription {
    const listeners = this.listeners.get(event) ?? new Map()
    const key = this.key++
    listeners.set(key, listener)
    this.listeners.set(event, listeners)
    return new EventSubscriptionImpl(this, event, key)
  }

  removeListener(subscription: EventSubscription) {
    const mySubscription = subscription as EventSubscriptionImpl
    const listeners = this.listeners.get(mySubscription.event)
    listeners?.delete(mySubscription.key)
  }

  emit<T>(event: EventName, context?: T) {
    const listeners = this.listeners.get(event)
    listeners?.forEach((listener) => listener(context))
  }

  async emitSync<T>(event: EventName, context?: T) {
    const listeners = this.listeners.get(event)
    if (listeners) {
      for (const listener of listeners.values()) {
        await listener(context)
      }
    }
  }
}

export const EventBus = new EventBusImpl()

/**
 * Custom hook to add an event listener to the {@link EventBus}. The listener is cleaned
 * up when the component unmounts.
 *
 * @param event - The event name to listen for.
 * @param listener - The listener function to be called when the event occurs. The listener must be
 *  created with the `useEffectEvent` hook, or the behavior is undefined.
 * @returns A function to remove the event listener.
 */
export function useEventBusEvent<T>(
  event: EventName,
  listener: (context: T) => void | Promise<void>,
) {
  useEffect(() => {
    const subscription = EventBus.addListener(event, listener)
    return () => subscription.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])
}
